import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    theme: {
        themes:{
            light: {
                upcolor: '#2E7D32',
                downcolor: '#FF3D00',
                maincolor: '#263238'
            }
        }
    }
});
