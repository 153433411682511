<template>
    <v-main class='white--text top-header'>
        <v-dialog persistent v-model="dialog" width="500">
            <v-card> 
            <v-card-title>{{currency}}</v-card-title>
            <v-card-text>
                <v-text-field v-model="amount" solo placeholder="Amount $"></v-text-field>
                <span class='subtitle-1' v-if="price">Price ${{price}}</span>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="addCurrency"  class='upcolor white--text'>Add</v-btn>
                <v-btn @click="cancelAdding" class='downcolor white--text'>Cancel</v-btn>
            </v-card-actions>
            
            </v-card>
        </v-dialog>
        <div class='siteLogo ma-3'>
            <h4>ZeroDropt</h4>
        </div>
        <v-container>
            <div class='header-content'>
                <h2 class='text-h2'>Trade like a Pro </h2>
                <h4 class='text-h4 font-weight-light'>with our online trading tools</h4>
                <div class='search-bar'>
                    <v-autocomplete :items="items" v-model="selectedId" placeholder="Search" height="58" prepend-inner-icon="mdi-magnify" class='mb-2 search-bar' solo></v-autocomplete>
                    <v-btn @click="submitCoin" class='subtitle-1 font-weight-bold yellow black--text search-btn'  large>Submit</v-btn>
                </div>
        </div>
    
        </v-container>
    </v-main>

</template>
<script>
import axios from 'axios';
export default{
    data(){
        return{
            selectedId: null,
            items: null,
            dialog: false,
            price: null,
            currency: null,
            amount: null,
        }
    },
    methods:{
        submitCoin(){
            if(this.selectedId != null){
                let data = {
                    required: 'updateCoin',
                    id : this.selectedId,
                    type: 'singleUpdate'
                }
                let currency = this.items.filter(element => {
                    return element.value == this.selectedId;
                })
                this.currency = currency[0].text;

                axios.post('api/api.php',data).then(response => {
                    this.price = response.data;
                })
                this.dialog = true;

            }
        },
        cancelAdding(){
            this.amount = null;
            this.selectedId = null;
            this.currency = null;
            this.price = null;
            this.dialog = false;
        },
        addCurrency(){
            let data = {
                id : parseInt(this.selectedId),
                name: this.currency,
                price: this.price,
                spend: parseInt(this.amount)
            };

            // RESET
            this.dialog = false;
            this.selectedId = null;
            this.price = null;
            this.currency = null;
            this.amount = null;
            
            this.$emit('add-currency',data);
        }
    },
    mounted(){  
        axios.defaults.withCredentials = true;
        axios.get('api/api.php', {
            params: {
                required: 'getCoins'
            }
        }).then(response => {
            this.items = response.data;
        })
    },

}
</script>
<style scoped>
.search-bar{
    margin-top: 50px !important;
}
.top-header{
    position: relative;
    background-image: url("../assets/header-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 600px;
    /* margin-top: 200px; */
}
.top-header::after{
    content: '' ;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
.siteLogo, .header-content{
    position: relative;
    z-index: 1;
}
.header-content{
    height: 100%;
    margin-top: 100px;
}
.theme--light.v-input
{
    height: 60px !important;
}
.search-btn{
    min-width: 200px !important;
}
</style>