<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
@import '../node_modules/typeface-roboto/index.css';
*{
  font-family: 'Cairo', sans-serif;
}
.v-application--wrap{
  overflow-x: hidden !important;
}
</style>