import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedCurrency: null,
  },
  getters:{
    selectedCurrency(state){
      return state.selectedCurrency;
    }
  },
  mutations: {
    selectCurrency(state,data){
      state.selectedCurrency = data;
    },
  },
  actions: {
    selectCurrency(context,data){
      context.commit("selectCurrency",data);
    }
  },
  modules: {
  }
})
