<template>
    <v-main>
        <v-container class='white--text'>
            <v-row  class='d-flex justify-center '>
                <v-col cols="12" class='coin-card' lg= "6">
                    <v-card class='card blue-grey darken-4 white--text' rounded>
                        <v-card-title><span class='teal--text text--lighten-1'>{{name}}</span> <span class='mx-2 subtitle-1'>${{livePrice}}</span></v-card-title>
                        <v-card-text class='white--text'>

                            <h5 class='text-h5 blue-grey--text text--lighten-1'>Spending</h5>
                            <span class='grey--text text--lighten-2 font-weight-bold'>${{spend}}</span>
                            <h6 class='text-h6'>Profit</h6>
                            <span v-if="getProfit" :class="getClass"><span>${{atPrice}}</span> ({{rate}})<v-icon :class="getClass" small>{{getIcon}} </v-icon> </span>
                            <span v-else>The currency doesn't up to the next zero </span>
                            <!-- <span class='red--text'><span>{{rate}}</span> ({{atPrice}})<v-icon class='red--text' small>mdi-arrow-down-bold </v-icon> </span> -->
                        </v-card-text>
                        <v-card-actions class='d-flex justify-end my-4'>
                            <v-btn @click="remove(id)" class='red white--text'>Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
export default{
    props: ["id",'name','at-price','live-price','spend'],
    computed:{
        rate(){
            return this.livePrice - (this.atPrice) == 0 ? 0 : ((this.livePrice - this.atPrice) / this.livePrice ) * 100;
        },
        getClass(){
            return this.rate > 0 ? 'green--text' : 'red--text';
        },
        getIcon(){
            return this.rate > 0 ? 'mdi-arrow-up-bold' : 'mdi-arrow-down-bold';
        },
        getProfit(){
            return this.atPrice * 10 <= this.livePrice ? true : false;
        }
    },
    methods:{
        remove(data){
            this.$emit('remove',data);
        }
    }
}
</script>
<style scoped>
.coin-card{
    min-height: 100px;
}
.coin-card .card{
    height: 100%;
    background-color: #020202 !important;
}

</style>