<template>
  <v-main class='body'>
    
      <top-header @add-currency="addCurrency"></top-header>
      <h3 class='text-center text-h3 white--text font-weigh-light my-4'>The Opening trades</h3>
      <currency-row @remove="deleteCurrency" v-for="currency in currencies" :spend="currency.spend" :name="currency.name" :id="currency.id" :atPrice="currency.price" :livePrice="currency.livePrice" :key="currency.id"></currency-row>
  </v-main>
</template>

<script>
import axios from 'axios'
let Storage = window.localStorage;
if(JSON.parse(Storage.getItem("currencies")) == null){
  Storage.setItem("currencies",JSON.stringify([]));
}

import TopHeader from './TopHeader.vue'
import CurrencyRow from '../components/CurrencyRow.vue';
export default {
  components: {TopHeader, CurrencyRow},
    data(){
      return{
        dialog: true,
        currencies: JSON.parse(Storage.getItem("currencies")),
      }
    },
    watch: {
      currencies(){
        if(this.currencies){
          setTimeout(() => {
            this.updatData();
          },5000)
        }
      }
    },
    methods:{
      addCurrency(data){
       this.currencies.push(data);
       Storage.setItem("currencies",JSON.stringify(this.currencies));
      },
      updatData(){
        let data = [];
        this.currencies.forEach(element => {
          data.push(element.id);
        })
        axios.defaults.withCredentials = true;
        let post = {
            required: 'updateCoin',
            ids : JSON.stringify(data),
            type: 'multiUpdate'
        }
        axios.post('api/api.php',post).then(response => {
          
          let livePrices = response.data;
          let newArr = this.currencies.map((item, i) => Object.assign({}, item, livePrices[i]));
          this.currencies = newArr; 
        })
      },
      deleteCurrency(data){
        let newCurrencies = this.currencies.filter(element => {
          return element.id != data;
        })
        this.currencies = newCurrencies;
        Storage.setItem('currencies',JSON.stringify(this.currencies));
      }
    },
    mounted(){   
      this.updatData()
    
    }
  }
</script>
<style>

</style>